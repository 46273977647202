









































import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { MyPageViewModel } from '../viewmodels/my-page-viewmodel'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  @Inject() vm!: MyPageViewModel
  createPostController = createPostController
  startPost() {
    this.vm.changeOpenEncouragePostDialog(false)
    createPostController.show(true)
  }
  closeDialog() {
    this.vm.changeOpenEncouragePostDialog(false)
  }
}
